import React, { useCallback, useEffect, useState, FormEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { QRCodeImg } from '@cheprasov/react-qrcode';
import logo from "../assets/Logo_Canon.png"
import { api, apiHomo } from "../services/api";
import ViewPDF from "../components/ViewPdf"
import { FaPenFancy } from "react-icons/fa";

import "./styles.css"
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import { AxiosError } from "axios";

interface Item {
    docno: number;
    name: string;
    pages: [
        {
            id: number;
            pdf: string;
            uid: string;
        }
    ]
}

interface SignatureProps {
    docNo: number,
    signature: String[],
    pag?: number,
    id: string;
}

interface Imagems {
    docNo: number,
    url: URLS[],
    pag?: number,
    id: string;
}

interface URLS {
    docNo?: number,
    img: String | undefined,
    pag?: number,
    id?: string;
}

interface DocumentsProps {
    antendimento: string,
    documents: URLS[]
}

export function Home() {
    const { id, tenant, token } = useParams();
    const navigate = useNavigate();


    const [imageCode, setImageCode] = useState<SignatureProps[]>([]);
    const [docno, setDocno] = useState<Number[]>([]);
    const [pdf, setPdf] = useState<Item[]>([]);

    const [atendimento, setAtendimento] = useState<String | undefined>("")

    const [isSalve, setIsSalve] = useState(true);
    const [isMobile, setMobile] = useState(false);
    const [show, setShow] = useState(false);
    const sigPad: any | null = {}

    const [classdd, setClassdd] = useState("")
    const [isVisible, setIsVisible] = useState(false);
    const [isActiveAssinar, setActiveAssinar] = useState(false);
    const [isActiveScroll, setActiveisActiveScroll] = useState(true);
    const [imagems, setImagems] = useState<Imagems[]>([])


    function showImagem(items: any) {

        const alreadySelected = imagems.findIndex(item => (item.docNo === items.docNo && item.pag === items.pag));

        if (alreadySelected >= 0) {

            if (imagems[alreadySelected].docNo === items.docNo && imagems[alreadySelected].pag === items.pag) {

                imagems[alreadySelected].url.push({
                    docNo: items.docNo,
                    img: items.url,
                    pag: items.pag,
                    id: items.id
                })
            }

            //filteredItems[alreadySelected].signature = data.signature            
            // setImageCode(filteredItems);
        } else {
            let aux: URLS[] = []

            aux.push({
                docNo: items.docNo,
                img: items.url,
                pag: items.pag,
                id: items.id
            })

            setImagems([...imagems, { docNo: items.docNo, url: aux, pag: items.pag, id: items.id }]);
        }
    }

    function setSignature(images: any) {
        console.log(images)


        const alreadySelected = imageCode.findIndex(item => (item.docNo === images.docno && item.pag === images.pag));

        if (alreadySelected >= 0) {

            if (imageCode[alreadySelected].docNo === images.docno && imageCode[alreadySelected].pag === images.pag) {
                //imageCode[alreadySelected].signature = data.signature
                imageCode[alreadySelected].signature.push(
                    images.signature,
                )
            }

            //filteredItems[alreadySelected].signature = data.signature            
            // setImageCode(filteredItems);
        } else {

            let aux: String[] = []

            aux.push(
                images.signature,
            )

            setImageCode([...imageCode, { docNo: images.docno, signature: aux, pag: images.pag, id: images.id }]);
            console.log(imagems)
        }
    }



    function ClearSignature(data: any) {
        console.log(data)

        const alreadySelected = imageCode.findIndex(item => {
            console.log(item)
            if (item.id === data.id) {

                return item
            }
        });
        console.log(alreadySelected)
        if (alreadySelected >= 0) {
            const filteredItems = imageCode.filter(item => (item.id !== data.id));
            setImageCode(filteredItems);
        }


        /* */

        const alreadySelectedImagems = imagems.findIndex(item => {
            console.log(item)
            if (item.id === data.id) {
                return item
            }
        });
        if (alreadySelectedImagems >= 0) {
            const filteredItemsImagem = imagems.filter(item => (item.id !== data.id));
            setImagems(filteredItemsImagem);
        }
    }




    async function handleSubmit(event: FormEvent) {

        event.preventDefault();
        setIsSalve(false);



        let docs: any = []
        //Recorremos el arreglo 
        imageCode.forEach((x, index) => {
            const docEncontrado = docs.find((e: { docNo: Number; }) => e.docNo === x.docNo)
            if (docEncontrado) {
                docEncontrado.signatures.push({ id: x.pag, img: x.signature })
            }
            else {
                docs.push({
                    docNo: x.docNo,
                    signatures: []
                })

                docs[index].signatures.push({ id: x.pag, img: x.signature })
            }
        })

        console.log(docs)
        if (!token) {
            await api.post(`signPdf`, {
                docs, tenant
            }).then(response => {
                console.log(response)
                if (response.status === 200) {
                    setIsSalve(true)
                    alert("Guia assinada com sucesso");
                    //limparAssinatura();
                    //window.location.reload();
                    navigate('/')
                }

            }).catch(error => {
                alert("Erro ao salvar a guia");
                setIsSalve(true);
            });
        } else {
            await apiHomo.post(`sendSignature`, {
                token, docs
            }).then(response => {
                console.log(response)
                if (response.status === 200) {
                    setIsSalve(true)
                    alert("Guia assinada com sucesso");
                    //limparAssinatura();
                    //window.location.reload();
                    navigate('/')
                }

            }).catch(error => {
                alert("Erro ao salvar a guia");
                setIsSalve(true);
            });

        }
        setIsSalve(true);


    }


    const base64toBlob = (data: string) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64

        const base64WithoutPrefix = data.substr(`data:application/pdf;base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        const PDF = new Blob([out], { type: "application/pdf" });
        const url = URL.createObjectURL(PDF);
        return url;
    };

    async function loadTela() {

        if (!token) {
            setAtendimento(id)
            await api.post("queryDocument", {
                "tenant": `${tenant}`, "id": id?.toString() ? id?.toString() : "0"
            }).then(async resp => {
                setDocno(resp.data.List);
                console.log(resp)
                const arquivos = await Promise.all(resp.data.List.map((docno: number) => {
                    return getPDF(docno)
                }))

                console.log(arquivos)

                let sortedAsceding = arquivos.sort((a, b) => {
                    return a.docno - b.docno;
                });

                setPdf(sortedAsceding);
            })
        } else {
            try {
                const result = await apiHomo.get(`getData/${token}`)

                setAtendimento(result.data.antendimento)

                const { documents } = result.data

                const pdfs = documents.map((_pages: any) => {
                    return {
                        docno: _pages.docNo,
                        name: _pages.name,
                        pages: getDocs(_pages)
                    }
                })
                setPdf(pdfs)
                
            } catch (error) {
                const err = error as AxiosError
                if(err.response?.status === 404){
                    navigate('/404')
                }
            }

        }
    }

    function getDocs(_pages: any) {
        return _pages.pages.map((result: any) => {
            return {
                id: result.id,
                pdf: result.pdf,
                uid: Math.floor(Date.now() * Math.random()).toString(36)
            }
        })
    }


    async function getPDF(docno: number) {
        console.log(docno)
        const arquivo = await api.post('getPdf', { "tenant": `${tenant}`, "docNo": docno });
        // const arquivo_convertido = base64toBlob(arquivo.data);
        console.log(arquivo);
        const pages = arquivo.data;
        const pdfs = pages.pages.map((pages: any) => {
            return {
                id: pages.id,
                pdf: base64toBlob(pages.pdf),
                uid: Math.floor(Date.now() * Math.random()).toString(36)
            }

        })


        return {
            docno: docno,
            name: pages.name,
            pages: pdfs
        }

    }



    function handleShowMenu() {
        if (classdd !== "") {
            setClassdd("")
        } else {
            setClassdd("show")
        }
    }

    const handleClickAssinatura = (tipo: string) => {
        if (tipo === "assinatura") {
            if (isActiveAssinar) {
                setActiveAssinar(false)
                setIsVisible(true)
            } else {
                setActiveAssinar(true)
                setIsVisible(false)
            }
            setActiveisActiveScroll(false)
        }

        if (tipo === "scroll") {
            if (isActiveScroll) {
                setActiveisActiveScroll(false)
            } else {
                setActiveisActiveScroll(true)
            }

            setIsVisible(true)
            setActiveAssinar(false)
        }

        setClassdd("")
    }


    useEffect(() => {

        const fetchData = async () => {
            if (pdf.length <= 0) {

                await loadTela();
            }
        }

        fetchData();
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setMobile(true);
        }

        console.log("loading")

    }, [pdf, isMobile, isActiveAssinar, isActiveScroll]);




    useCallback(() => {
    }, [isMobile]);

    function handleZoomOut() {
        const x = document.getElementsByName("zoomOut")
        x.forEach(element => {
            element.click()
        });
    }

    function handleZoomIn() {
        const x = document.getElementsByName("zoomIn")
        x.forEach(element => {
            element.click()
        });
    }


    return (
        <div id="page-create-point">
            <header className="page-header">
                <div className="container">
                    <section id="top" className="animate-up">
                        <h2 className="sr-only">Homepage</h2>
                        <img id="logo" src={logo} alt="Logo" />
                        <div className="qrCode">
                            {
                                !isMobile &&
                                <QRCodeImg value={window.location.href}
                                    level="Q"
                                    padding={2}
                                    scale={42}
                                    size={200}
                                    image={{
                                        source: logo,
                                        width: '40%',
                                        height: '10%',
                                        x: 'center',
                                        y: 'center',
                                    }}
                                />
                            }
                        </div>
                    </section>

                    <div className="separator"></div>

                    <section id="summary" className="animate-up delay-1">
                        <h2 className="sr-only">Sumário</h2>

                        <div className="info">
                            <div className="finished">
                                Atendimento
                                <strong>{atendimento}</strong>
                            </div>
                        </div>
                    </section>
                </div>
            </header>

            <div className="container" >

                {pdf.length > 0 &&
                    <>
                        <div className={`navflutuante show`} id="settings"
                            onClick={() => handleClickAssinatura("assinatura")}
                            style={{
                                background: isActiveAssinar ? "#0d7ff2" : "#ECEEF0",
                                color: isActiveAssinar ? "#FFFFFF" : "",
                            }}
                        >
                            <FaPenFancy size={24} color={"#000"} />

                        </div>
                        <div className={`navflutuante show`} id="create"
                            onClick={handleZoomIn}

                        >
                            <BsZoomIn size={24} color={"#000"} />

                        </div>
                        <div className={`navflutuante show`} id="thumb"
                            onClick={handleZoomOut}

                        >
                            <BsZoomOut size={24} color={"#000"} />
                            
                        </div>
                    </>
                }
                <main className="animate-up delay-2">
                    {
                        !show &&
                            pdf.length <= 0 ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10rem" }}>
                                <div className="classic-1"></div>
                            </div>
                            :


                            pdf.map((_pdfs, index) => {


                                return (

                                    _pdfs.pages.map((pages, index) => {

                                        return <div className="card" key={index} style={{ "marginBottom": "4.5rem" }}>

                                            <div className="titulopdf" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#000", fontSize: 40 }}>
                                                {_pdfs.name}
                                            </div>
                                            <div
                                                style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                                    height: 'auto',
                                                }}
                                            >

                                                <ViewPDF data={{
                                                    handleUrl: pages.pdf,
                                                    docno: _pdfs.docno,
                                                    pag: pages.id,
                                                    sigPad: sigPad,
                                                    id: pages.uid,
                                                }}
                                                    Signatures={setSignature}
                                                    ClearSignature={ClearSignature}
                                                    handleShowMenu={handleShowMenu}
                                                    classdd={classdd}
                                                    isActiveAssinar={isActiveAssinar}
                                                    isActiveScroll={isActiveScroll}
                                                    isVisible={isVisible}
                                                    handleClickAssinatura={handleClickAssinatura}
                                                    showImagem={showImagem}
                                                    imagems={imagems}
                                                />
                                            </div>
                                        </div>
                                    })
                                )
                            })

                    }




                    {!show &&
                        <form onSubmit={handleSubmit}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignContent: "flex-end",
                                paddingBottom: 20,

                            }}
                            >
                                {isSalve ?
                                    <button type="submit"
                                        className="button green"
                                        style={{ cursor: false ? "not-allowed" : "pointer" }}
                                    >
                                        Salvar
                                    </button>
                                    :
                                    <label className="">Salvando....</label>
                                }


                            </div>
                        </form>
                    }


                </main>
            </div>
            <div id="overlay" className={`${classdd !== "" ? "show" : ""}`}></div>
        </div >
    )
}